export const MY_ACCOUNT_ROUTE = "/auth/my-account";
export const ADMIN_ROUTE = "/admin";
export const PIM_ROUTE = "/pim";
export const XM_ROUTE = "/xm";
export const RTPE_ROUTE = "/rtpe";
export const OMS_ROUTE = "/oms";
export const OFFERS_ROUTE = "/offers";
export const CSR_ROUTE = "/csr";

/** Unleash Feature Flags*/
export const COPILOT_HEADER_RTPE = "copilot-header-rtpe";
export const COPILOT_HEADER_MEMBER = "copilot-header-member";
export const COPILOT_HEADER_MARKETPLACE = "copilot-header-marketplace";
export const COPILOT_HEADER_PIM = "copilot-header-pim";
export const COPILOT_HEADER_XM = "copilot-header-xm";
export const COPILOT_HEADER_OFFERS = "copilot-header-offers";
export const COPILOT_HEADER_OMS = "copilot-header-oms";
export const COPILOT_HEADER_CDP = "copilot-header-customers";

export const COPILOT_SELF_ONBOARDING = "copilot-self-onboarding";
export const REFRESH_INTERVAL = 3600; // 60 X 60 seconds
export const COPILOT_HEADER_TERMINOLOGY_UPDATE =
  "copilot-header-terminology-update";
export const COPILOT_HEADER_ACCOUNT_SWITCHER_ID_ADDITION =
  "copilot-header-account-switcher-id-addition";
export const COPILOT_HEADER_L1_LINKS_UPDATE = "copilot-header-l1-links-update";
export const COPILOT_ADMIN_DS4_SAMPLE_DATA = "copilot-ds4-sample-data";
export const COPILOT_CONVERSATIONAL_AI_HEADER = "conversational-ai-header";
export const COPILOT_SHOW_FABAI = "show-fabai";

export const TENANT_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  IN_PROGRESS: "IN_PROGRESS",
};

export const SESSION_STORAGE_KEYS = {
  ACCESS_TOKEN: "accessToken",
  ACCOUNT: "account",
  ACCOUNT_ID: "accountId",
  ORG_DETAIL: "orgDetail",
  TENANT_DETAIL: "tenantDetail",
  ACCOUNT_NAME: "accountName",
  USER_ID: "user",
};

export const LOCAL_STORAGE_KEYS = {
  IS_ID_V2: "isLoggedInWithIdV2",
  REFRESH_TOKEN: "refreshToken",
  OKTA_STORE: "okta-token-storage",
};
